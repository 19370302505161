import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import user from './modules/user';
import game from './modules/game';
import getters from './getters'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => {
    const saveState = {};
    saveState.user = {
      authToken: state.user.authToken,
    };
    saveState.game = {
      firstLevelResult: state.game.firstLevelResult,
    };

    return saveState;
  },
});

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters,
  modules: {
    user,
    game,
  },
  plugins: [vuexLocal.plugin],
});
