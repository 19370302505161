import axios from 'axios';
import router from '../../router';

export default {
  namespaced: true,
  state: {
    currentUser: null,
    currentUserGame: null,
    authToken: null,
  },
  mutations: {
    SET_USER: (state, payload) => {
      if (payload) {
        state.currentUser = payload.user;
        state.currentUserGame = payload.game;
      }
    },
    SET_TOKEN: (state, payload) => {
      state.authToken = payload;
      axios.defaults.headers.common.Authorization = `Bearer ${payload}`;
    },
  },
  getters: {
    isLogined: (state) => state.currentUser && state.currentUser.id,
    isGameFinished: (state) => state.currentUserGame && !!state.currentUserGame.position,
  },
  actions: {
    async logout({ state, commit }) {
      try {
        console.log('[vuex-user] logout start')
        await axios.post('/logout')
      }
      catch (e) {
        console.log('[vuex-user] logout error', e)
      }
      finally {
        commit('SET_USER', {});
        commit('SET_TOKEN', null);
        commit('game/CLEAR_STATE', null, { root: true });
        router.replace('/');
        localStorage.clear()
        document.location.reload();
        console.log('[vuex-user] logout done');
      }
    },
    async fetchUser({ commit, state }) {
      try {
        if (state.authToken) {
          commit('SET_TOKEN', state.authToken);
        }
        await axios.get('/users/current').then((resp) => { commit('SET_USER', resp.data); });
      } catch (e) {
        commit('SET_USER', null);
      }
    },
  },
};
