import axios from 'axios';
import { CONF_SETTINGS } from '../../confSettings';
import { msToTime, getDigitalTime } from '../../utils/helpers';

const defaultState = {
  marquee: { text: CONF_SETTINGS.marquee, type: 'info' },
  firstLevelComplete: false,
  secondLevelComplete: false,
  firstLevelStart: null,
  firstLevelResult: null,
  leaderboard: { top: [], current: null },
  showIntention: true,
};

const mutations = {
  SET_MARQUEE: (state, { text, type }) => {
    state.marquee = { text, type };
  },
  CLEAN_MARQUEE: (state) => {
    state.marquee = defaultState.marquee;
  },
  SET_FIRST_LEVEL_COMPLETE: (state) => {
    state.firstLevelComplete = true;
  },
  SET_SECOND_LEVEL_COMPLETE: (state) => {
    state.secondLevelComplete = true;
  },
  SET_FIRST_LEVEL_START: (state) => {
    const now = new Date()
    state.firstLevelStart = now.toJSON();
  },
  SET_FIRST_LEVEL_RESULT: (state) => {
    const finish = new Date();
    const start = new Date(state.firstLevelStart);
    const diff = (finish - start);

    state.firstLevelResult = getDigitalTime(diff);
  },
  SET_LEADERBOARD: (state, payload) => {
    state.leaderboard = payload;
  },
  HIDE_INTENTION: (state) => {
    state.showIntention = false
  },
  CLEAR_STATE: (state) => {
    state = defaultState;
  }
};

const actions = {
  setMarquee({ commit }, payload) {
    commit('SET_MARQUEE', payload);
    setTimeout(() => {
      commit('CLEAN_MARQUEE');
    }, 3000)
  },
  setFirstLevelComplete({ commit }) {
    setTimeout(() => {
      commit('SET_FIRST_LEVEL_COMPLETE');
    }, 2000)
  },
  setSecondLevelComplete({ commit }) {
    setTimeout(() => {
      commit('SET_SECOND_LEVEL_COMPLETE');
    }, 2000)
  },
  setFirstLevelStart({ commit }) {
    commit('SET_FIRST_LEVEL_START')
  },
  setFirstLevelResult({ commit }) {
    commit('SET_FIRST_LEVEL_RESULT')
  },
  async fetchLeaderboard({ commit }) {
    try {
      await axios.get('/game/get-top').then((resp) => {
        const top = resp.data.top.map((item) => {
          return { ...item, duration: msToTime(item.duration) };
        })
        let current = resp.data.current;
        if (current) {
          current = { ...current, duration: msToTime(current.duration) }
        }
        commit('SET_LEADERBOARD', { current: current, top: top });
      });
    } catch (e) {
      commit('SET_LEADERBOARD', defaultState.leaderboard);
    }
  },
  hideIntention({ commit }) {
    commit('HIDE_INTENTION');
  }
};

const getters = {
};

export default {
  namespaced: true,
  state: { ...defaultState },
  mutations,
  actions,
  getters,
};
