export const msToTime = (s) => {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;

  return mins + ":" + secs.toString().padStart(2, "0") + "." + `${ms}`[0];
}

export const getDigitalTime = (ms) => {
  const s = ms / 1000;
  const numminutes = Math.floor(
    (((s % 31536000) % 86400) % 3600) / 60
  )
    .toString()
    .padStart(2, "0");
  const numseconds = Math.floor((((s % 31536000) % 86400) % 3600) % 60)
    .toString()
    .padStart(2, "0");

  return `${numminutes}:${numseconds}`;
}