export const CONF_SETTINGS = {
  prizes: [
    { place: '1 место', title: 'Рюкзак и ж/диск на 1 ТБ', image: '/images/prizes/backpack-and-hdd.png' },
    { place: '2 место', title: 'ж/диск на 1 ТБ', image: '' },
    { place: '3 место', title: 'Рюкзак', image: '' },
    { place: '4-5 место', title: 'Термокружка', image: '' }
  ],
  schedule: [
  ],
  marquee: 'Лучшие получат призы',
  vacancies: [
    {
      position: 'iOS - разработчик',
      description: 'Команда Сбер Инвестор в поисках iOS-разработчика. Работаем на Swift, используем SPM, UIKit, SwiftUI, Core Data, graphQL, socket.io, MVVM, Clean. Design system. Тебя ждут - работа по Agile, масштабные задачи, создание уникального продукта с нуля и возможность сделать лучшее инвестиционное приложение в России. Welcome!',
      url: 'https://hh.ru/vacancy/49514603',
      tags: [],
      telegram: 'https://hmsg.me/v18698',
    },
    {
      position: 'Android-разработчик',
      description: 'Команда СберИнвестор разрабатывает уникальный продукт с нуля - новое брокерское приложение. Если ты Android-разработчик и у тебя есть опыт работы Kotlin + Coroutines - тебе к нам. Используем Android Jetpack, работаем по Agile. Присоединяйся!',
      url: 'https://hh.ru/vacancy/49514594',
      tags: [],
      telegram: 'https://hmsg.me/v18700',
    },
    {
      position: 'Android-разработчик',
      description: 'SberDevices активно расширяют команду платформы и ищут крутого Android-разработчика. Мы делаем умные устройства  которые меняют мир. Кодовое слово - Kotlin, присоедийся!',
      url: 'https://hh.ru/vacancy/49835787',
      tags: [],
      telegram: 'https://hmsg.me/v18704',
    },
    {
      position: 'iOS-разработчик',
      description: 'Амбициозная команда SberDevices в поисках iOS-разработчиков разного уровня. Пишем на Swift, работаем с монорепозиторием, Git, Bitbucket. Становись частью команды, меняющей мир!',
      url: 'https://nn.hh.ru/vacancy/49058168',
      tags: [],
      telegram: 'https://hmsg.me/v18701',
    },
    {
      position: 'iOS-разработчик',
      description: 'Команда Рекламный инвентарь работает над созданием инструментов, позволяющих доносить до клиента релевантные предложения группы Сбера. Нам нужен опытный iOS - разработчик для разработки модуля, отвечающего за весь рекламный контент приложения СБОЛ. С тебя отклик, с нас подробности.',
      url: 'https://hh.ru/vacancy/49823677',
      tags: [],
      telegram: 'https://hmsg.me/v18712',
    },
    {
      position: 'iOS-разработчик',
      description: 'Ждем iOS - разработчика в амбициозную команду, которая работает над пакетами, подписками и тарифами в Сбербанк Онлайн. Весь новый функционал пишем на Swift, наши инструменты Xcode, Git, Bash, Sourcetree, AnyConnect, Charles, Figma. Присоединяйся!',
      url: 'https://hh.ru/vacancy/49631700',
      tags: [],
      telegram: 'https://hmsg.me/v18705',
    },
    {
      position: 'iOS-разработчик',
      description: 'Команда PFM (персональное финансовое планирование) в поисках iOS-разработчика с уверенным знанием Swift, чтобы создавать лучшие сервисы управления личными финансами. Мы ждем тебя!',
      url: 'https://hh.ru/vacancy/48960915',
      tags: [],
      telegram: 'https://hmsg.me/v18706',
    },
    {
      position: 'iOS-разработчик',
      description: 'Команда СберТранспорт в поисках тимлида iOS-разработки-гуру Swift. Вместе  с командой профессионалов тебе предстоит разрабатывать и улучшать существующее приложение. Откликайся!',
      url: 'https://hh.ru/vacancy/49467485',
      tags: [],
      telegram: 'https://hmsg.me/v18707',
    },
    {
      position: 'Android-разработчик',
      description: 'Команда Диалоги в Сбербанк Онлайн в поисках Android-разработчика.  Вместе мы будем создавать Мессенджер в приложении, который входит в ТОП-10 лучших в Google Play.',
      url: 'https://hh.ru/vacancy/49645597',
      tags: [],
      telegram: 'https://hmsg.me/v18710',
    },
    {
      position: 'Android-разработчик',
      description: 'Ищем Android-разработчика, готового к изменению мира платежных решений для аудитории 60М+. Тебя ждут современные технологии и лучшие практики. Присоединяйся!',
      url: 'https://hh.ru/vacancy/49821908',
      tags: [],
      telegram: 'https://hmsg.me/v18708',
    },
    {
      position: 'Android-разработчик',
      description: ' Присоединяйся к команде развития приложения СберБизнес. Уже сейчас наша аудитория более 1 млн пользователей. У нас амбициозная цель - сделать самый большой и самый современный корпоративный банк. Для Andoid-разработчика у нас есть современный стек технологий и возможности для быстрого профессионального роста. Ждем тебя.',
      url: 'https://hh.ru/vacancy/49187067',
      tags: [],
      telegram: 'https://hmsg.me/v18711',
    },
  ]
}
