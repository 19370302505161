import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

Vue.use(VueRouter)

// Components

// Guards
const needAuth = (to, from, next) => {
  if (from.name === 'Stages') {
    next();
    return
  }
  if (store.getters.isLogined) {
    next();
    return;
  }
  next('/');
}
const needNotAuth = (to, from, next) => {
  if (!store.getters.isLogined) {
    next();
    return;
  }
  next('/stages');
}
const needGameUnfinished = () => {
  if (!store.getters.isGameFinished) {
    next();
    return;
  }
  next('/stages');
}
// const needGameFinished = () => {}

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "main-layout" */ '@/layouts/MainLayout.vue'),
    props: { withFooter: true },
    children: [
      {
        path: '',
        name: 'Landing',
        component: () => import(/* webpackChunkName: "landing-page" */ '@/pages/Landing/index.vue'),
        beforeEnter: needNotAuth,
      },
      {
        path: 'game',
        name: 'Game',
        component: () => import(/* webpackChunkName: "game-page" */ '@/pages/Game/index.vue'),
        beforeEnter: async (to, from, next) => {
          await store.dispatch('user/fetchUser').then(() => {
            if (store.getters.isLogined) {
              if (!store.getters.isGameFinished) {
                next();
                return;
              }
              next('/stages');
              return;
            }
            next('/');
          });
        }
      },
      {
        path: 'results/:hash',
        name: 'Results',
        component: () => import(/* webpackChunkName: "results-page" */ '@/pages/Results/index.vue'),
      },
      {
        path: 'top',
        name: 'Top',
        component: () => import(/* webpackChunkName: "top-page" */ '@/pages/Top/index.vue')
      },
      {
        path: 'stages',
        name: 'Stages',
        component: () => import(/* webpackChunkName: "stages-page" */ '@/pages/Stages/index.vue'),
        beforeEnter: async (to, from, next) => {
          if (!store.state.game.firstLevelResult) {
            next({ name: 'Landing' });
          }
          await store.dispatch('user/fetchUser').finally(() => next());
        }
      },
      {
        path: 'job',
        name: 'Job',
        component: () => import(/* webpackChunkName: "stages-page" */ '@/pages/Job/index.vue'),
      },
      {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "stages-page" */ '@/pages/NotFound/index.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
