<style lang="postcss">
.version {
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  left: 0px;
  background: rgba(255,0,0,.5);
  color: white;
  font-size: 10px;
  padding: 0px 4px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<template>
  <div id="app">
    <div class="version">
      <small> v{{ version }} </small>
    </div>
    <router-view />
  </div>
</template>

<script>
import "animate.css";

export default {
  name: 'App',
  computed: {
    version () {
      return process.env.VUE_APP_VERSION
    }
  },
}
</script>
