import '@/setup/installCompositionApi.js'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import auth from '@/setup/auth.js'
import helpers from '@/setup/helpers.js'
import libs from '@/setup/libs.js'

import 'windi.css'
import '@/assets/fonts.css'
import '@/assets/base.css'
import '@/assets/ui.css'

Vue.config.productionTip = false

// Setup stuff
auth(Vue, router, store)
helpers(Vue, router, store)
libs(Vue, router, store)

store.dispatch('user/fetchUser').then(() => {
  new Vue({
    store,
    router,
    render: h => h(App)
  }).$mount('#app')
})
