import axios from 'axios'

export default function (Vue, router, store) {

  // Axios
  axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL + '/api'
  axios.defaults.withCredentials = false // ! true вызовет ошибку CORS
  axios.defaults.headers['Accept'] = 'application/json'
  axios.defaults.headers['Content-Type'] = 'application/json'

  // Auth
  const uriParams = new URLSearchParams(window.location.search)
  const authToken = uriParams.get('token')
  console.log('[auth.js]', authToken)

  if (store.state.user.authToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${store.state.user.authToken}`;
  }

  if (authToken) {
    console.log('[auth.js] Token found!', authToken);
    store.commit('user/SET_TOKEN', authToken);
    axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    if (window.opener) {
      console.log('[auth.js] isOPENER window.opener.postMessage...')
      window.opener.postMessage({ token: authToken }, '*')
    }
    else {
      console.log('[auth.js] noOPENER window.postMessage')
      window.postMessage({ token: authToken }, '*')
    }
  }

  // Axios interceptors
  axios.interceptors.response.use(
    function (response) {
      // console.log('not error');
      return response;
    },
    function (error) {
      // console.log('errorl));
      let allowed = ['/'];
      let allwedUrl = ['/users/current'];

      if (!allowed.includes(window.location.pathname) && !allwedUrl.includes(error.response.config.url)) {
        store.dispatch("game/setMarquee", {
          text: `Ошибка: ${error.message}`,
          type: "error",
        });
      }

      throw error;
    },
  );
  Vue.prototype.$http = axios.create();
}
